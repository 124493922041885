import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {slideInAnimation} from '../../animations';
import {ServiceUnavailable, StoreHeader} from '../../interface';
import {AppEventsService, AppRouteService, ErrorhandlerService} from '../../../core/services';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TokenService} from '../../../core/services';
import {HeaderService} from '../../../core/services';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'coop-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slideInAnimation]
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  activeTab = 0;
  pageTitle;
  headerMode: StoreHeader = {
    cartOnly: false,
    cartAndShop: true,
    shopOnly: false,
    pickUpTime: false
  };
  customerId;
  storeKardex;
  callbackUrlSupport = false;
  showStoreHeader: boolean = false;
  mainPageContainer;
  showOpaque: boolean = true;
  showOpaqueHeader: boolean = true;
  serviceNotAvailableModal: ServiceUnavailable = {show: false, title: '', information: ''};
  private _subscriptionState: boolean = true;

  constructor(private _appEventsService: AppEventsService,
              private _errorHandleService: ErrorhandlerService,
              private _router: Router,
              private _routerService: AppRouteService,
              private _renderer: Renderer2,
              private _headerService: HeaderService) {
  }

  ngOnInit(): void {
    this.activeTab = +TokenService.activeTab;
    this.storeKardex = TokenService.storeKardex;
    this.customerId = TokenService.customerId;
    this.callbackUrlSupport = TokenService.callbackUrl;
    this._appEventsService.$tabChange.pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe(active => {
      this.activeTab = active;
    });
    this._setPaddingTop();
    this._setTabRoute();
    this._setHeaders();
    this._setInitialOpaqueState();
    if (this.activeTab !== 0) {
          this.showOpaqueHeader = false; // reseting opaque header value for when in tab section
     }
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState)
      )
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
            if (this.activeTab === 0) {
                this._routerService.setCurrentAppRoute(e.url);
                const isInShopHome = e.url.includes(`/coop/shop/${TokenService.storeKardex}`);
                const isOrderSection = e.url.includes(`/coop/order/${TokenService.storeId}`)
                    || e.url.includes(`/coop/cart-verify`);
                this.showOpaque = this._showOpaqueBody(isInShopHome);
                this.showOpaqueHeader = this._showOpaqueHeader(isInShopHome, isOrderSection);
            }
        }
      });
    this._appEventsService.$showServiceUnavailableModal.pipe(
        takeWhile(() => this._subscriptionState)
    ).subscribe(
        state => {
                this.serviceNotAvailableModal = state;
        },
        err => this._handleError(err)
    );
  }
  ngAfterViewInit(): void {
      this._initMainContainerHeight();
  }
 private _setInitialOpaqueState(): void {
      if (TokenService.activeTab === '0') {
          this.showOpaqueHeader = false;
          this.showOpaque = false;
      }
 }
 private _showOpaqueBody(inHome: boolean): boolean {
      return !inHome;
 }
 private _showOpaqueHeader(inHome: boolean, inOrder: boolean): boolean {
      return !inHome && !inOrder;
 }
  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  tab(option: number): void {
    this.activeTab = option;
    if (option === 0) {
    //  const currentAppUrl = this._routerService.getCurrentAppRoute();
      this._router.navigate(['/coop/cart-verify']);
    }
    if (option !== 0) {
      this._router.navigate([`/coop/iframe-content/${option}`]);
    }
  }
  private _setPaddingTop(): void {
    const appPage = document.getElementById('app-page');
    appPage.style.paddingTop = (TokenService?.paddingTop || 0)  + 'px';
  }

  private _setHeaders(): void {
    this._headerService.setHeader('Test Header', TokenService.tab, 'none', false);
  }
  private _setTabRoute(): void {
    const firstTabUrl = `${environment.firstTabUrl}?kardex=${this.storeKardex}&userId=${this.customerId}&callbackUrlSupport=${this.callbackUrlSupport}`;
    const secondTabUrl = `${environment.secondTabUrl}?kardex=${this.storeKardex}` + (this.customerId ? `&userId=${this.customerId}` : '') + `&callbackUrlSupport=${this.callbackUrlSupport}`;
    const thirdTabUrl  = `${environment.thirdTabUrl}?kardex=${this.storeKardex}` + '&source=nyheder-tilbud&close=1' + (this.customerId ? `&userId=${this.customerId}` : '');
    this._routerService.setTabRoutes(firstTabUrl, secondTabUrl, thirdTabUrl);
  }
  private _initMainContainerHeight(): void {
    this.mainPageContainer = document.getElementById('main-page-container');
    this.mainPageContainer.style.height = (window.innerHeight - (TokenService?.paddingTop || 0) ) + 'px';
  }
  prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  private _handleError(error): void {
      this._errorHandleService.handleError(error, 'system');
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this._initMainContainerHeight();
  }
}
