import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router} from '@angular/router';
import {AppEventsService, CartService, TokenService} from '../services';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {
    constructor(private _router: Router,
                private _appEvents: AppEventsService) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!TokenService.jwtToken) {
            this.setAppData(route.queryParams);
            this._router.navigate(['verify']).then(
                () => {
                    if (!environment.production) {
                        console.log('Token From URL used');
                        TokenService.jwtToken = route.queryParamMap.get('token');
                    }
                }
            );
            return false;
        }
        return true;
    }

    setAppData(routeData: any): void {
        this.setStoreKardex(routeData.storeKardex);
        this.setOpenTab(routeData.openTab);
        this.setCallbackUrl(routeData.callbackUrlSupport);
    }

    setStoreKardex(storeKardex: any): void {
        storeKardex ?
            (TokenService.storeKardex = storeKardex) :
            TokenService.storeKardex = null;
    }

    setOpenTab(state: any): void {
        state ?
            TokenService.openTab = state :
            TokenService.openTab = null;
    }

    setCallbackUrl(state: boolean): void {
        state ?
            TokenService.callbackUrl = state :
            TokenService.callbackUrl = false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (TokenService.isSet) {
            return true;
        }
        this._router.navigate([`/404`]);
        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(childRoute);
    }
}

@Injectable({
    providedIn: 'root'
})
export class VerifyGuard implements CanActivate, CanActivateChild {
    constructor(private _router: Router,
                private _location: Location) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!TokenService.isSet) {
            return true;
        }
        TokenService.activeTab = '2';
        this._router.navigate([`/coop/iframe-content/${TokenService.activeTab}`]).then();
        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(childRoute);
    }
}
