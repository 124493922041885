<header class="app-header clearfix not-print" [ngClass]="bgColor" id="app-header">
  <div class="page-title-section">
    <div class="wrapper">
        <span class="back-btn not-print" (click)="back()">
            <i class="icon-arrow-left"></i>
        </span>
      <div class="page-title">
        <span>{{pageTitle}}</span>
      </div>
    </div>
  </div>
  <ul class="tabs three-col" id="page-header-tabs" [class.non-transparent]="activeTab !== 0" *ngIf="showTab">
    <li class="tab-first" #tab1 (click)="tab(1, tab1)" [class.active]="activeTab === 1">Deltag</li>
    <li class="tab-second" #tab2 (click)="tab(2, tab2)" [class.active]="activeTab === 2">Opslagstavle</li>
    <li class="tab-third" #tab3 (click)="tab(3, tab3)" [class.active]="activeTab === 3">Dine Ja tak</li>
    <li class="tab-fourth"  #tab4 (click)="tab(0, tab4)" [class.active]="activeTab === 0" *ngIf="!env.production">Hent mad</li>
    <hr>
  </ul>
</header>

